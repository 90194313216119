<template>
  <base-card title="🔔 Tasks">
    <ion-accordion-group value="tasks">
      <ion-accordion value="tasks">
        <ion-item slot="header" class="ion-no-padding">
          <h4>To Do ({{ activeTasksLength }})</h4>
        </ion-item>

        <ion-list slot="content">
          <task-preview-list-item v-for="task in tasks" :key="task.id" :router-link="`/tasks/${task.id}`" :task="task"
            @check-task="$emit('check-task', task)">
          </task-preview-list-item>
        </ion-list>
      </ion-accordion>
    </ion-accordion-group>
  </base-card>
</template>

<script>
import { IonAccordionGroup, IonAccordion, IonList, IonItem } from "@ionic/vue";
import TaskPreviewListItem from "@/components/tasks/TaskPreviewListItem.vue";

export default {
  props: {
    tasks: {
      Object: Array,
      required: true,
    },
  },

  components: {
    IonAccordionGroup,
    IonAccordion,
    TaskPreviewListItem,
    IonList,
    IonItem,
  },

  data() {
    return {};
  },

  computed: {
    userId() {
      return this.$route.params.id
    },

    activeTasksLength() {
      return this.tasks.filter((x) => x.status === 0).length
    }
  }
};
</script>