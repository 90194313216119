<template>
  <ion-item :lines="lines" class="ion-no-padding">
    <ion-checkbox slot="start" :checked="task.status" @ionChange="handleClick($event)"></ion-checkbox>

    <ion-label class="ion-text-wrap ion-no-margin" :router-link="`/tasks/${task.id}`">
      <div class="p" :class="task.status ? 'strikethrough' : ''">
        <div>
          <strong>{{ task.title }}</strong>
        </div>

        <div v-if="task.user">
          <strong>Guest:</strong> {{ task.user.fullname }}
        </div>

        <div v-if="task.due_date" :class="overdue ? 'danger' : ''">
          <strong>Due:</strong> {{ formatDate(task.due_date, "MMM d, yyyy") }}
          {{ overdue ? "(Overdue)" : "" }}
        </div>
      </div>
    </ion-label>

    <ion-chip color="primary" slot="end" v-if="task.assigned_role" class="ion-text-capitalize">
      {{ assignedRole }}
    </ion-chip>
  </ion-item>
</template>

<script>
import { IonItem, IonCheckbox, IonLabel, IonChip } from "@ionic/vue";
import { formatDate } from "@/util/helpers";

export default {
  props: {
    task: {
      type: Object,
      required: true,
    },
    lines: {
      type: String,
      default: "full",
    },
  },

  components: {
    IonItem,
    IonCheckbox,
    IonLabel,
    IonChip,
  },

  data() {
    return {
      formatDate,
    };
  },

  computed: {
    assignedRole() {
      if (this.task.assigned_role) {
        if (this.task.assigned_role === "tasks_manager") return "Management";
        else if (this.task.assigned_role === "tasks_care_associate") return "Care Team";
      }

      return ""
    },

    overdue() {
      if (new Date(this.task.due_date) < new Date() && this.task.status === 0) {
        return true;
      }

      return false;
    },
  },

  methods: {
    handleClick() {
      this.$emit("check-task", this.task);
    },
  },
};
</script>

<style scoped>
ion-item {
  --padding-bottom: 8px;
  --padding-top: 8px;
  --border-color: var(--light-line-color);
}

.ion-text-wrap {
  font-size: 16px;
}

ion-checkbox {
  position: relative;
}

.strikethrough {
  text-decoration: line-through;
  color: #bababa;
}
</style>