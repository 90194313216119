<template>
  <base-layout-one page-title="Home">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>

    <ion-grid>
      <!-- Guests In House -->
      <ion-row>
        <ion-col size="12">
          <base-card :title="`🏠 Guests In House (${inHouse.length})`" link-text="See All" :link-url="`/users`">
            <swiper :modules="modules" :initial-slide="0" :breakpoints="breakpoints">
              <swiper-slide v-for="user in inHouse" :key="user.id">
                <user-card :user="user" class="width-100-percent"></user-card>
              </swiper-slide>
            </swiper>
          </base-card>
        </ion-col>
      </ion-row>

      <!-- Guests Arriving -->
      <ion-row>
        <ion-col>
          <base-card :title="`🛬 Guests Arriving (${arriving.length})`" link-text="See All" :link-url="`/users`">
            <swiper :modules="modules" :initial-slide="0" :breakpoints="breakpoints">
              <swiper-slide v-for="user in arriving" :key="user.id">
                <user-card :user="user" class="width-100-percent"></user-card>
              </swiper-slide>
            </swiper>
          </base-card>
        </ion-col>
      </ion-row>

      <!-- Guests Departing -->
      <ion-row>
        <ion-col>
          <base-card :title="`🛫 Guests Departing (${departing.length})`" link-text="See All" :link-url="`/users`">
            <swiper :modules="modules" :initial-slide="0" :breakpoints="breakpoints">
              <swiper-slide v-for="user in departing" :key="user.id">
                <user-card :user="user" class="width-100-percent"></user-card>
              </swiper-slide>
            </swiper>
          </base-card>
        </ion-col>
      </ion-row>

      <!-- Tasks -->
      <ion-row>
        <ion-col>
          <tasks-preview-section :tasks="sortedTasks" @check-task="checkTask">
          </tasks-preview-section>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout-one>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonicSlides, IonRefresher, IonRefresherContent } from "@ionic/vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import UserCard from "@/components/users/UserCard.vue";
import TasksPreviewSection from "@/components/tasks/TasksPreviewSection.vue";
import { format, subDays, addDays } from "date-fns";
import { mapGetters, mapActions } from "vuex";

// Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "@ionic/vue/css/ionic-swiper.css";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonRefresher,
    IonRefresherContent,
    Swiper,
    SwiperSlide,
    UserCard,
    TasksPreviewSection,
  },

  data() {
    return {
      user: {},
      tasks: [],
      sortedTasks: [],
      users: [],
      inHouse: [],
      arriving: [],
      departing: [],
      modules: [IonicSlides],
      breakpoints: {
        320: {
          slidesPerView: 2.5,
          spaceBetween: 16,
        },
        480: {
          slidesPerView: 3.5,
          spaceBetween: 16,
        },
        640: {
          slidesPerView: 4.5,
          spaceBetween: 16,
        },
      },
    };
  },

  computed: {
    ...mapGetters(["authUser"]),
  },

  watch: {
    tasks() {
      // Sort by Due Date oldest to newest
      this.sortedTasks = this.tasks.sort((a, b) => {
        if (new Date(b.due_date) > new Date(a.due_date)) return -1;
        if (new Date(b.due_date) < new Date(a.due_date)) return 1;

        if (a.id > b.id) return 1;
        if (a.id < b.id) return -1;
      });
    },
  },

  async ionViewDidEnter() {
    this.fetchMe();
    this.fetchInHouse();
    this.fetchArriving();
    this.fetchDeparting();
    this.fetchTasks();
  },

  methods: {
    ...mapActions(["authLogout"]),

    /**
     * Refresh method on pulldown
     * Consider deprecating since it should load whenever they come back to the tab
     * 
     * @param {*} event 
     */
    async refresh(event) {
      this.fetchInHouse();
      this.fetchArriving();
      this.fetchDeparting();
      this.fetchTasks();

      event.target.complete();
    },

    /**
     * Fetch Me
     */
    async fetchMe() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/users/${this.authUser.id}`)
        .then((response) => {
          this.user = response.data.user

          // Log the user out if they are not active
          if (!this.user.active) {
            this.authLogout()
            this.$router.replace("/auth/login");
          }
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch In House Guests
     */
    async fetchInHouse() {
      let today = format(subDays(new Date(), 0), "yyyy-MM-dd");

      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations?arrival_date_before=${today}&departure_date_after=${today}`)
        .then((response) => {
          // Convert the Reservations into Users
          this.inHouse = response.data.reservations.map((x) => x.user);
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Arriving Guests
     */
    async fetchArriving() {
      let yesterday = format(subDays(new Date(), 1), "yyyy-MM-dd");
      let tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd");

      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations?arrival_date_before=${tomorrow}&arrival_date_after=${yesterday}`)
        .then((response) => {
          // Convert the Reservations into Users
          this.arriving = response.data.reservations.map((x) => x.user);
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Departing Guests
     */
    async fetchDeparting() {
      let yesterday = format(subDays(new Date(), 1), "yyyy-MM-dd");
      let tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd");

      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations?departure_date_before=${tomorrow}&departure_date_after=${yesterday}`)
        .then((response) => {
          // Convert the Reservations into Users
          this.departing = response.data.reservations.map((x) => x.user);
        })
        .catch((error) => { console.log(error) });
    },


    /**
     * Fetch Tasks for this User
     */
    async fetchTasks() {
      // Fetch tasks for the highest role of this user
      let highestRole = null

      if (this.authUser.role === "care_associate")
        highestRole = "tasks_care_associate"

      else if (this.authUser.roles.findIndex((x) => x.name === "tasks_manager") >= 0)
        highestRole = "tasks_manager"

      await this.axios
        .get(`${process.env.VUE_APP_API}/tasks/?status=0&assigned_role=${highestRole}`)
        .then((response) => { this.tasks = response.data.tasks })
        .catch((error) => { console.log(error) });
    },


    /**
     * Marks a task as complete or incomplete
     * This needs to be revisited
     * 
     * @param {Object} task 
     */
    async checkTask(task) {
      if (task.status) task.status = false;
      else task.status = true;

      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/tasks/${task.id}`,
        data: task,
      };

      await this.axios(config)
        .then(() => { this.fetchTasks() })
        .catch((error) => { console.log(error) });
    },
  },
};
</script>

<style scoped>
.width-100-percent {
  width: 100%;
}
</style>