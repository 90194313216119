<template>
  <ion-card :router-link="`/users/${user.id}`" class="ion-no-margin margin-x-8 margin-y-8 card-outline">
    <div class="ion-padding">
      <ion-avatar>
        <img :src="user.profile_photo_url
          ? user.profile_photo_url
          : 'https://borym.s3.amazonaws.com/media/soft_green_light.png'
          " />
      </ion-avatar>
      <div class="h6 margin-top-8 ion-text-center">{{ user.first_name }}</div>
      <div class="h6 ion-text-center">{{ user.last_name }}</div>
    </div>
  </ion-card>
</template>

<script>
import { IonCard, IonAvatar } from "@ionic/vue";

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonCard,
    IonAvatar,
  },

  data() {
    return {};
  },
};
</script>

<style scoped>
ion-card {
  color: var(--ion-text-color);
}

ion-avatar {
  margin: auto;
}
</style>